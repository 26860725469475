import { useCallback } from "react";
import { OnestSemiBoldSmall } from "../styled/TextComponents";
import { styles } from "./styles";


const TextInput = ({
    value,
    multiline = false,
    width,
    height,
    title,
    placeholder = null,
    defaultValue = null,
    inputBackgroundColor = null,
    onChange,
    type = "text",
}) => {
    let attrObj = {};


    const memoizedOnChange = useCallback((e) => {
        if (typeof onChange === "function") {
            onChange(e);
        }
    }, [onChange]);


    return (
        <div style={{ ...styles.infoEditContainer }}>
            <OnestSemiBoldSmall>{title}</OnestSemiBoldSmall>
            {multiline === false ?
                <input
                    value={value}
                    type={type}
                    min="0"
                    defaultValue={defaultValue}
                    {...attrObj}
                    placeholder={placeholder}
                    style={{
                        ...styles.infoEditSmallTextInput,
                        width: width,
                        height: height,
                        backgroundColor: inputBackgroundColor,
                        // paddingLeft: 1
                    }}
                    onChange={memoizedOnChange}

                />
                :
                <textarea
                    value={value}
                    defaultValue={defaultValue}
                    {...attrObj}
                    placeholder={placeholder}
                    style={{
                        ...styles.infoEditSmallTextInput,
                        width: width,
                        height: height,
                        resize: "none",
                        backgroundColor: inputBackgroundColor
                    }}
                    onChange={memoizedOnChange}
                />
            }
        </div>
    )
}

export default TextInput;