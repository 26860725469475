import { useEffect, useState, useCallback } from "react";

import PersonalDefaultPage from "../PersonalDefaultPage";
import { useDispatch, useSelector } from "react-redux";
import {
    OnestBoldBig,
    OnestNormalBig,
    OnestNormalDefault,
    OnestNormalSmall, OnestSemiBoldBig, OnestSemiBoldDefault, OnestSemiBoldSmall
} from "../../../components/styled/TextComponents";
import {
    color_black, color_blue, color_blue_light, color_green, color_green_light,
    color_grey_dark,
    color_grey_light,
    color_red_default,
    color_red_light, color_red_ultra_light, color_transparent,
    color_white, color_yellow, color_yellow_light
} from "../../../constants/colors";
import {
    BsArrowLeft,
    BsArrowRight,
    BsArrowsAngleExpand,
    BsArrowsExpand,
    BsClock,
    BsDoorOpen, BsRepeat,
    BsReply, BsReplyFill
} from "react-icons/bs";
import { updateSchdeule } from "../../../store/ScheduleSlice";
import { ModalDefault } from "../../../components/containers/ModalDefault";
import { updateDayLessonData } from "../../../store/ScheduleDayExtendedSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../../Config";
import { addZeroToTime, weekDayToFullWeekName } from "../JournalPage";
import { updateStudent } from "../../../store/StudentSlice";
import { LoadingContainer } from "../../../components/containers/LoadingContainer";
import getRussianNumberEnding from "../../../utilities/rusNumbers";
import ModalNote from "../../../components/blocks/SchedulePage/ModalNote";
import ModalReschedule from "../../../components/blocks/SchedulePage/ModalReschedule";
import { set } from "react-hook-form";
import ModalSimple from "../../../components/blocks/ModalSimple";
import getUserLessonStatus from "../../../utilities/getUserLessonStatus";
import formatDateForFastAPI from "../../../utilities/formatDateForFastAPI";
import { updateUser } from "../../../store/UserSlice";

const ScheduleHeader = ({ scheduleWeekRange, setScheduleWeekRange }) => {
    const monthNames = ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"]
    const monthFullNames = ["ЯНВАРЬ", "ФЕВАЛЬ", "МАРТ", "АПРЕЛЬ", "МАЙ", "ИЮНЬ", "ИЮЛЬ", "АВГУСТ", "СЕНТЯБРЬ", "ОКТЯБРЬ", "НОЯБРЬ", "ДЕКАБРЬ"]


    const scheduleWEekRangeShortString = () => {
        console.warn("scheduleWeekRange", scheduleWeekRange)
        return `${scheduleWeekRange[0].getDate() < 10
            ? `0${scheduleWeekRange[0].getDate()}`
            : scheduleWeekRange[0].getDate()} ${monthNames[scheduleWeekRange[0].getMonth()]} — ` +
            `${scheduleWeekRange[1].getDate() < 10
                ? `0${scheduleWeekRange[1].getDate()}`
                : scheduleWeekRange[1].getDate()} ${monthNames[scheduleWeekRange[1].getMonth()]}`
    }


    const setPreviousWeek = () => {
        let weekStart = new Date(scheduleWeekRange[0]);
        weekStart.setDate(weekStart.getDate() - 7);
        let weekEnd = new Date(scheduleWeekRange[1]);
        weekEnd.setDate(weekEnd.getDate() - 7);
        setScheduleWeekRange([weekStart, weekEnd]);
    }

    const setNextWeek = () => {
        let weekStart = new Date(scheduleWeekRange[0]);
        weekStart.setDate(weekStart.getDate() + 7);
        let weekEnd = new Date(scheduleWeekRange[1]);
        weekEnd.setDate(weekEnd.getDate() + 7);
        setScheduleWeekRange([weekStart, weekEnd]);
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <OnestBoldBig style={{ marginRight: 8 }}>{scheduleWeekRange[0].getFullYear()}, </OnestBoldBig>
                <OnestNormalBig style={{ color: color_grey_dark }}>{
                    monthFullNames[scheduleWeekRange[0].getMonth()] === monthFullNames[scheduleWeekRange[1].getMonth()] ?
                        monthFullNames[scheduleWeekRange[0].getMonth()] :
                        `${monthFullNames[scheduleWeekRange[0].getMonth()]} - ${monthFullNames[scheduleWeekRange[1].getMonth()]}`
                }</OnestNormalBig>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: 2
            }}>
                <div style={{
                    backgroundColor: color_white,
                    width: 32,
                    height: 32,
                    borderTopLeftRadius: 100,
                    borderBottomLeftRadius: 100,
                    color: color_black,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    padding: 5,
                    fontSize: 20,
                    cursor: "pointer"
                }} onClick={() => setPreviousWeek()}>
                    <BsArrowLeft />
                </div>
                <div style={{
                    height: 32,
                    backgroundColor: color_white,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    padding: 5,
                    width: 150
                }}>
                    <OnestNormalDefault>{scheduleWEekRangeShortString()}</OnestNormalDefault>
                </div>
                <div style={{
                    backgroundColor: color_white,
                    width: 32,
                    height: 32,
                    borderTopRightRadius: 100,
                    borderBottomRightRadius: 100,
                    color: color_black,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    padding: 5,
                    fontSize: 20,
                    cursor: "pointer"
                }} onClick={() => setNextWeek()}>
                    <BsArrowRight />
                </div>
            </div>
        </div>
    )
}

const ScheduleDayheader = ({ weekday, lessonsCount }) => {
    const weekDayString = () => {
        const weekdays = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
        return weekdays[getNormalizedWeekDay(weekday.getDay())]
    }

    function getNormalizedWeekDay(weekDay) {
        if (weekDay === 0) return 6
        else return weekDay - 1
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            width: "100%"
        }}>
            <OnestSemiBoldDefault>{weekDayString()}</OnestSemiBoldDefault>
            <OnestNormalDefault>*</OnestNormalDefault>
            <OnestNormalDefault> {lessonsCount} урок{getRussianNumberEnding(lessonsCount)}</OnestNormalDefault>
        </div>
    )
}

const ScheduleDayBodyHeader = ({ day, weekday, lessons }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // alignItems: "center"
        }}>
            <div style={{
                width: 50,
                height: 50,
                backgroundColor: [6, 0].includes(weekday.getDay()) ? color_red_default : color_transparent,
                color: [6, 0].includes(weekday.getDay()) ? color_white : color_black,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <OnestNormalBig style={{ fontSize: 40 }}>{day.getDate() < 10 ? `0${day.getDate()}` : `${day.getDate()}`}</OnestNormalBig>
            </div>
            <div style={{
                width: 25,
                height: 25,
                backgroundColor: color_red_default,
                color: color_white,
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                fontSize: 10,
                marginTop: 10,
                cursor: "pointer"
            }}
                onClick={() => {
                    dispatch(updateDayLessonData({ date: day, lessons: lessons }))
                    navigate("/service/schedule/day")
                }}>
                <BsArrowsAngleExpand />
            </div>
        </div>
    )
}

const LessonPreview = ({ lessonData, onOpenModalNote, onOpenModalReschedule }) => {
    const lessonColor = getLessonColor(lessonData.paymentStatus);
    const lessonStatusColor = getLessonStatusColor(lessonData.paymentStatus);

    const [showMenu, setShowMenu] = useState(false);

    function getLessonColor(paymentStatus) {
        if (paymentStatus === "Не оплачено") {
            return color_red_ultra_light
        } else if (paymentStatus === "Последний урок") {
            return color_yellow_light
        } else if (paymentStatus === "Оплачено") {
            return color_green_light
        } else {
            return color_blue_light
        }
    }

    function getLessonStatusColor(paymentStatus) {
        if (paymentStatus === "Не оплачено") {
            return color_red_light
        } else if (paymentStatus === "Последний урок") {
            return color_yellow
        } else if (paymentStatus === "Оплачено") {
            return color_green
        } else {
            return color_blue
        }
    }

    const handleEditLesson = useCallback(() => {
        let lessonDataUpstream = { ...lessonData, lessonStatusColor: lessonStatusColor, lessonColor: lessonColor }
        console.log("Edit lesson with ID:", lessonData.id);
        // Add your edit lesson logic here
        onOpenModalReschedule(lessonDataUpstream);
    }, [lessonColor, lessonData, lessonStatusColor, onOpenModalReschedule]);

    const handleOpenNote = useCallback(() => {
        let lessonDataUpstream = { ...lessonData, lessonStatusColor: lessonStatusColor, lessonColor: lessonColor }
        console.log("handleOpenNote", lessonData.id);
        // Add your edit lesson logic here
        onOpenModalNote(lessonDataUpstream);
    }, [lessonColor, lessonData, lessonStatusColor, onOpenModalNote]);

    const [startTime, endTime, quantityTime] = lessonData.timeSlot?.split(" - ") || ["", "", 1];

    return (
        <div style={{
            width: "100%",
            backgroundColor: lessonColor,
            display: "flex",
            flexDirection: "column",
            borderRadius: 12,
            padding: 10,
            boxSizing: "border-box",
            gap: 5,
            cursor: "pointer",
            transition: "background-color 0.3s ease, transform 0.3s ease"
        }}
            onClick={() => setShowMenu(!showMenu)}
        >
            {showMenu && (
                <div style={{
                    backgroundColor: color_white,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    transform: "scaleY(1)",
                    transformOrigin: "top",
                    transition: "transform 0.3s ease"
                }}>
                    <div
                        style={{
                            color: color_red_default,
                            cursor: "pointer"
                        }}
                        onClick={() => handleEditLesson(lessonData.id)}
                    >
                        Изменить урок
                    </div>
                    <div
                        style={{
                            color: color_red_default,
                            cursor: "pointer"
                        }}
                        onClick={handleOpenNote}
                    >
                        Открыть заметку
                    </div>
                </div>
            )}
            {lessonData.studentImage !== null &&
                <img
                    style={{
                        width: "100%",
                        height: 60,
                        borderRadius: 12,
                        objectFit: "cover",
                        alignSelf: "center",
                    }}
                    src={Config.CLOUD_ADDR + "/" + lessonData.studentImage}
                    alt={lessonData.studentLevel}
                />
            }
            <OnestSemiBoldSmall>{lessonData.studentName}</OnestSemiBoldSmall>
            <OnestNormalSmall style={{ color: lessonStatusColor }}>{lessonData.paymentStatus}</OnestNormalSmall>
            <div style={{
                width: "100%",
                height: "auto",
                backgroundColor: color_white,
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly"
            }}>
                <BsClock style={{ width: 50, margin: 4 }} />
                <OnestNormalSmall style={{ margin: 8 }}>{startTime} {endTime}</OnestNormalSmall>
            </div>
            <OnestNormalSmall style={{ margin: 8 }}>{quantityTime}</OnestNormalSmall>
        </div>
    )
}

const ScheduleDayBody = ({ day, weekday, lessons, onOpenModalNote, onOpenModalReschedule }) => {
    let newLessons = [...lessons]
    newLessons.sort((a, b) => new Date(a.dateDate) - new Date(b.dateDate))

    const timeRanges = [
        { start: "00:00", end: "10:00" },
        { start: "10:00", end: "14:00" },
        { start: "14:00", end: "18:00" },
        { start: "18:00", end: "21:00" },
        { start: "21:00", end: "24:00" },
    ];

    const renderLessonsForTimeRange = (start, end) => {
        const filteredLessons = newLessons.filter(lesson => {
            const [lessonStart] = lesson.timeSlot.split(" - ");
            return lessonStart >= start && lessonStart < end;
        });

        if (filteredLessons.length > 0) {
            return filteredLessons.map(el => (
                <LessonPreview
                    key={el.id}
                    lessonData={el}
                    onOpenModalNote={onOpenModalNote}
                    onOpenModalReschedule={onOpenModalReschedule}
                />
            ));
        } else {
            return <div key={start} style={{  display: "flex", flex: 1 }} />;
        }
    };


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            gap: 5,
            overflow: "auto",
            padding: 5,
            boxSizing: "border-box"

        }}>
            <ScheduleDayBodyHeader
                day={day}
                weekday={weekday}
                lessons={lessons} />
            <div style={{  
                display: "flex",
                flexDirection: "column", 
                justifyContent: "space-between",
                height: "100%",
                }} >
                {timeRanges.map(({ start, end }) => (
                    
                    renderLessonsForTimeRange(start, end)
                    
                ))}
            </div>
        </div>
    )
}

const ScheduleDay = ({ weekday, day, lessons, onOpenModalNote, onOpenModalReschedule }) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: 150,
            height: "100%"

        }}>
            <ScheduleDayheader weekday={weekday} lessonsCount={lessons.length} />
            <div style={{
                width: "100%",
                height: "100%",
                backgroundColor: color_white,
                borderRadius: 10,
            }}>
                <ScheduleDayBody
                    day={day}
                    weekday={weekday}
                    onOpenModalNote={onOpenModalNote}
                    onOpenModalReschedule={onOpenModalReschedule}
                    lessons={lessons} />
            </div>
        </div>
    )
}

const ScheduleCalendar = ({ schedule, weekDayRange, onOpenModalNote, onOpenModalReschedule }) => {

    const weekFullRange = () => {
        let weekDays = [];
        let weekStart = new Date(weekDayRange[0]);
        while (getNormalizedWeekDay(weekStart.getDay()) <= getNormalizedWeekDay(weekDayRange[1].getDay())) {
            weekDays.push(new Date(weekStart));
            if (getNormalizedWeekDay(weekStart.getDay()) === getNormalizedWeekDay(weekDayRange[1].getDay())) break;
            weekStart.setDate(weekStart.getDate() + 1);
        }
        return weekDays;
    }

    function getNormalizedWeekDay(weekDay) {
        if (weekDay === 0) return 7
        else return weekDay
    }

    const scheduleToDays = splitScheduleByWeekDays(schedule);
    function splitScheduleByWeekDays(schedule) {
        let scheduleByWeekDay = [];

        weekFullRange().forEach(weekday => {
            scheduleByWeekDay.push({
                weekday: weekday,
                lessons: schedule.filter(el => el.dateDate.getDay() === weekday.getDay())
            })
        })
        return scheduleByWeekDay
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            height: "100%",
        }}>
            {scheduleToDays.map(el =>
                <ScheduleDay
                    weekday={el.weekday}
                    day={el.weekday}
                    lessons={el.lessons}
                    onOpenModalNote={onOpenModalNote}
                    onOpenModalReschedule={onOpenModalReschedule}
                />)}
            {/*<ScheduleDay weekday={"ПН"} day={"01"} lessons={schedule}/>*/}
        </div>
    )
}


export const SchedulePage = () => {
    const schedule = useSelector(state => state.schedule);
    const student = useSelector(state => state.student);
    const user = useSelector(state => state.user);
    const [scheduleWeekRange, setScheduleWeekRange] = useState(getWeekRangeForDate(new Date()));
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isShowModalNote, setIsShowModalNote] = useState(false);
    const [isShowModalReschedule, setIsShowModalReschedule] = useState(false);
    const [lessonForModal, setLessonForModal] = useState({});
    const [modalSuccessText, setModalSuccessText] = useState("");
    const [modalAlertText, setModalAlertText] = useState("");
    const [modalErrorText, setModalErrorText] = useState("");

    const getStudent = async (studentId) => {
        try {
            let res = await axios.get(Config.BACKEND_ADDR + `/students/${studentId}`)
            let downloadedData = res.data
            dispatch(updateStudent({
                "studentId": downloadedData.id,
                "lessonPrice": downloadedData.lesson_cost,
                "balance": downloadedData.balance,
                "age": downloadedData.age,
                "image": downloadedData.image ? Config.CLOUD_ADDR + "/" + downloadedData.image : process.env.PUBLIC_URL + "/no_student_icon.png",
                "payedLessons": parseInt(downloadedData.balance / downloadedData.lesson_cost),
                "name": downloadedData.name,
                "englishLevel": downloadedData.english_skill,
                "additionalInfo": downloadedData.comment,
            }))
        } catch (error) {
            console.error("Error fetching student data:", error);
        }
    }

    const memoOpenModalNote = useCallback((lesson) => {
        getStudent(lesson.studentId);
        setLessonForModal(lesson);
        setIsShowModalNote(true);
    }, []);

    const memoOpenModalReschedule = useCallback((lesson) => {
        getStudent(lesson.studentId);
        setLessonForModal(lesson);
        setIsShowModalReschedule(true);
    }, []);

    const memoCloseModalNote = useCallback(() => {
        setLessonForModal({});
        setIsShowModalNote(false);
    }, []);

    const memoSaveNote = async (comment, commentOption, lessonData) => {

        const updateLesson = async (lessonIdForUpd) => {
            try {
                let updRes = await axios.patch(Config.BACKEND_ADDR + `/lessons/note/`, {
                    lesson_id: lessonIdForUpd,
                    note: comment,
                })
                return updRes;

            } catch (error) {
                return error;

            }
        }

        const successAction = async () => {
            setLessonForModal({});
            setIsShowModalNote(false);
            await loadData()
            setModalSuccessText("Заметка добавлена");
        }


        if (commentOption === "lesson") {
            try {
                let resUpd = await updateLesson(lessonData.id);
                if (resUpd.status === 200) {
                    await successAction();
                } else {
                    console.error("Error updating lesson:", resUpd.data);
                    setModalErrorText("Ошибка обновления урока");
                }

            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        } else {

            let numberDays = (commentOption === 'week') ? 7 : 31;

            let lessonsForUpdate = schedule.filter(el => {
                const lessonDate = el.dateDate;
                const startDate = new Date(lessonData.dateDate);
                const endDate = new Date(lessonData.dateDate);
                endDate.setDate(endDate.getDate() + numberDays);
                return lessonDate >= startDate && lessonDate <= endDate && el.studentId === lessonData.studentId;
            });
            try {
                for (const lesson of lessonsForUpdate) {

                    let resUpd = await updateLesson(lesson.id);
                    if (resUpd.status === 200) {
                        console.log("Заметка добавлена на ", lesson.id, resUpd.data);
                        //await successAction();
                    } else {
                        console.error("Error updating lesson:", resUpd.data);
                        setModalErrorText("Ошибка обновления урока");
                    }
                }

                await successAction();

            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        }




    };

    const memoSaveDateTime = async (dateTime, isDelete, isPaid, lessonData) => {

        if (isDelete){
            setModalAlertText("удалится только указанный урок, для удаления всех уроков в казанный день недели нужно воспользоваться карточкой студента. ");
        
        }else{
            setModalAlertText("перенос произойдёт однократно, для переноса уроков навсегда нужно воспользоваться карточкой студента. ");
        
        }
       
        await getStudent(lessonData.studentId);
        
        const [startHour, startMinute] = lessonData.timeSlot.split(" - ")[0].split(":").map(Number);
        const [endHour, endMinute] = lessonData.timeSlot.split(" - ")[1].split(":").map(Number);

        const intervalMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);


        const endDateTime = new Date(dateTime);
        endDateTime.setTime(endDateTime.getTime() + intervalMinutes * 60 * 1000);

        const end_time = formatDateForFastAPI(endDateTime);
        const start_time = formatDateForFastAPI(new Date(dateTime));


        const updateLesson = async (lessonIdForUpd, start_time, end_time) => {
            try {
                let updRes = await axios.patch(Config.BACKEND_ADDR + `/lessons/datetime/`, {
                    lesson_id: lessonIdForUpd,
                    start_time: start_time,
                    end_time: end_time,
                })
                return updRes;

            } catch (error) {
                return error;

            }
        }

        const successAction = async (text) => {
            setLessonForModal({});
            setIsShowModalReschedule(false);
            await loadData()
            setModalSuccessText(text ? text : "Изменения сохранены");
        }


        if (isDelete) {

            try {
                let resDelete = await axios.delete(Config.BACKEND_ADDR + `/lessons/${lessonData.id}`);

                if (resDelete.status === 200) {
                    await successAction("Занятие удалено");
                } else {
                    console.error("Error updating lesson:", resDelete.data);
                    setModalErrorText("Ошибка удаления урока");
                }

                if (isPaid) {
                    
                    let newBalance = +student.balance - +lessonData.cost
                    console.warn("student.balance", student.balance)
                    let resUpdStudent = await axios.patch(Config.BACKEND_ADDR + `/students/balance/`, {
                        balance: newBalance,
                        student_id: lessonData.studentId,
                    })
                    if (resUpdStudent.status === 200) {
                        dispatch(updateStudent({ studentId: lessonData.studentId, balance: newBalance }))
                    } else {
                        console.error("Error updating student balance:", resUpdStudent.data);
                        setModalErrorText("Ошибка обновления баланса ученика");
                    }

                    let userBalance = user.balance ? user.balance : 0;
                    const newTeacherBalance = +userBalance + +lessonData.cost
                    let resUpdTeacher = await axios.patch(Config.BACKEND_ADDR + `/auth/teacher/balance`, {
                        balance: newTeacherBalance,
                    })
                    if (resUpdTeacher.status === 200) {
                        dispatch(updateUser({ userId: user.id, balance: newTeacherBalance }))
                    } else {
                        console.error("Error updating teacher balance:", resUpdTeacher.data);
                        setModalErrorText("Ошибка обновления баланса учителя");
                    }

                }

            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        } else {
            try {

                let resUpd = await updateLesson(lessonData.id, start_time, end_time);
                if (resUpd.status === 200) {
                    await successAction();
                } else {
                    console.error("Error updating lesson:", resUpd.data);
                    setModalErrorText("Ошибка обновления урока");
                }


            } catch (error) {
                console.error("Error updating lesson:", error);
            }
        }


    };

    const memoCloseModalReschedule = useCallback(() => {
        setLessonForModal({});
        setIsShowModalReschedule(false);
    }, []);

    function getWeekRangeForDate(date) {

        let weekStart = new Date(date);
        let weekEnd = new Date(date);

        const dayOfWeek = weekStart.getDay();
        const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

        weekStart.setDate(weekStart.getDate() - diffToMonday);
        weekStart.setHours(0, 0, 0, 0);

        weekEnd.setDate(weekStart.getDate() + 6);
        weekEnd.setHours(23, 59, 59, 999);

        console.warn("weekStart", weekStart)
        console.warn("weekEnd", weekEnd)

        return [weekStart, weekEnd]
    }

    function updateWeek(date) {
        setScheduleWeekRange(date);
    }

    async function loadData() {
        let response = await axios.get(Config.BACKEND_ADDR + "/lessons/")
        let lessonsData = response.data
        let uniqueStudents = lessonsData
            .map(el => el.student_id)
            .filter((value, index, array) => array.indexOf(value) === index);
        let studentsBalance = {}

        for (let studentId of uniqueStudents) {
            response = await axios.get(Config.BACKEND_ADDR + `/students/${studentId}`)
            studentsBalance[studentId] = {
                balance: response.data.balance,
                cost: response.data.lesson_cost,
                english_skill: response.data.english_skill,
                name: response.data.name,
                image: response.data.image,
            }
        }

        const formattedLessons = lessonsData.map(el => {
            let startTime = new Date(el.start_time);
            let endTime = new Date(el.end_time);
            return {
                id: el.id,
                studentName: studentsBalance[el.student_id].name,
                studentImage: studentsBalance[el.student_id].image,
                studentId: el.student_id,
                dateDate: startTime,
                timeSlot:
                    `${addZeroToTime(startTime.getHours())}:${addZeroToTime(startTime.getMinutes())} - ` +
                    `${addZeroToTime(endTime.getHours())}:${addZeroToTime(endTime.getMinutes())} - ` + el.quantity + " занятий",
                paymentStatus: getUserLessonStatus(studentsBalance, el.student_id),
                weekDay: weekDayToFullWeekName(el.weekday).short,
                studentLevel: studentsBalance[el.student_id].english_skill,
                note: el.note,
                cost: studentsBalance[el.student_id].cost,
            };
        });
        console.warn("formattedLessons", formattedLessons)
        dispatch(updateSchdeule(formattedLessons));
        // dispatch(updateSchdeule(getWeekLessonsFromApi(scheduleWeekRange)))
        return true
    }

    useEffect(() => {
        loadData()
        setIsLoading(false)
    }, [scheduleWeekRange])
    /* 
        function getWeekLessonsFromApi(weekDayRange) {
            const weekFullRange = () => {
                var weekDays = [];
                let weekStart = new Date(weekDayRange[0]);
                while (getNormalizedWeekDay(weekStart.getDay()) <= getNormalizedWeekDay(weekDayRange[1].getDay())) {
                    weekDays.push(new Date(weekStart));
                    if (getNormalizedWeekDay(weekStart.getDay()) === getNormalizedWeekDay(weekDayRange[1].getDay())) break;
                    weekStart.setDate(weekStart.getDate() + 1);
                }
                return weekDays;
            }
            function getNormalizedWeekDay(weekDay) {
                if (weekDay === 0) return 6
                else return weekDay - 1
            }
            const weekDayString = ({ weekday }) => {
                const weekdays = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
                return weekdays[getNormalizedWeekDay(weekday.getDay())]
            }
            let baseId = 0;
            let lessons = [];
            let minLessons = 0;
            let maxLessons = 7;
            let counter = 0;
            weekFullRange().forEach(weekDate => {
                let dayLessonsCount = parseInt(Math.random() * (maxLessons - minLessons) + minLessons);
                let lessonsCounter = 0;
                while (lessonsCounter < dayLessonsCount) {
                    let minuteSlots = ["00", "30"];
                    let randomTime = parseInt(Math.random() * (18 - 6) + 6);
                    let randomMinute = minuteSlots[parseInt(Math.random() * (1))];
                    let lessonDate = new Date(weekDate)
                    lessonDate.setHours(randomTime)
                    lessonDate.setMinutes(randomMinute)
                    lessonDate.setSeconds(0)
                    lessons.push({
                        id: counter,
                        studentName: "Наташа Сизова",
                        dateDate: lessonDate,
                        timeSlot: `${randomTime < 10 ? `0${randomTime}` : `${randomTime}`}:${randomMinute}-${randomTime + 1 < 10 ? `0${randomTime + 1}` : `${randomTime + 1}`}:${randomMinute === "00" ? "30" : "00"}`,
                        paymentStatus: ["Оплачено", "Не оплачено", "Последний урок"][parseInt(Math.random() * (2))],
                    })
                    lessonsCounter += 1
                    counter += 1
                }
            })
            return lessons
        }
    
     */

    return (
        <PersonalDefaultPage>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 20
            }}>
                <LoadingContainer isLoading={isLoading}>
                    <ModalSimple
                        type={"success"}
                        h2={modalSuccessText}
                        isOpen={modalSuccessText !== ""}
                        onClose={setModalSuccessText}
                    ></ModalSimple>
                    <ModalSimple
                        type={"alert"}
                        h2={modalAlertText}
                        isOpen={modalAlertText !== ""}
                        onClose={setModalAlertText}
                    ></ModalSimple>
                    <ModalNote
                        lessonData={lessonForModal}
                        isOpen={isShowModalNote}
                        onClose={memoCloseModalNote}
                        onSave={memoSaveNote}
                    />
                    <ModalReschedule
                        lessonData={lessonForModal}
                        isOpen={isShowModalReschedule}
                        onClose={memoCloseModalReschedule}
                        onSave={memoSaveDateTime}
                    />
                    <ScheduleHeader
                        scheduleWeekRange={scheduleWeekRange}
                        setScheduleWeekRange={(date) => updateWeek(date)} />
                    <ScheduleCalendar
                        onOpenModalNote={memoOpenModalNote}
                        onOpenModalReschedule={memoOpenModalReschedule}
                        schedule={schedule
                            .filter(el =>
                                el.dateDate >= scheduleWeekRange[0]
                                && el.dateDate <= scheduleWeekRange[1]
                            )}
                        weekDayRange={scheduleWeekRange} />
                </LoadingContainer>
            </div>
        </PersonalDefaultPage>
    )
}