import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation, useBlocker } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { styles } from "./styles"
import { OnestBoldSmall, OnestNormalDefault, OnestNormalSmall, OnestSemiBoldSmall } from "../../../styled/TextComponents";
import { Button } from "../../../controls/Button/Button";
import { color_grey_light, color_grey_ultra_light, color_red_default, color_red_ultra_light, color_white } from "../../../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../Config";
import axios from "axios";
import { updateStudent } from "../../../../store/StudentSlice";
import { englishLevelsObjectArray } from "../../../../constants/levels";
import Config from "../../../../Config";

export const TextInput = ({
    multiline = false,
    width,
    height,
    title,
    placeholder = null,
    inputKey,
    defaultValue = null,
    inputBackgroundColor = null,
    onChange,
    value,
    type = "text",
}) => {
   


    const memoizedOnChange = useCallback((e) => {
        if (typeof onChange === "function") {
            onChange(e);
        }
    }, [onChange]);


    return (
        <div style={{ ...styles.infoEditContainer }}>
            <OnestSemiBoldSmall>{title}</OnestSemiBoldSmall>
            {multiline === false ?
                <input
                    type={type}
                    value={value}
                    min="0"
                    defaultValue={defaultValue}
                   
                    placeholder={placeholder}
                    style={{
                        ...styles.infoEditSmallTextInput,
                        width: width,
                        height: height,
                        backgroundColor: inputBackgroundColor,
                        // paddingLeft: 1
                    }}
                    onChange={memoizedOnChange}

                />
                :
                <textarea
                    defaultValue={defaultValue}
                    
                    placeholder={placeholder}
                    style={{
                        ...styles.infoEditSmallTextInput,
                        width: width,
                        height: height,
                        resize: "none",
                        backgroundColor: inputBackgroundColor
                    }}
                    onChange={memoizedOnChange}
                />
            }
        </div>
    )
}


const LevelSelectorButtons = ({ onChange }) => {
    //const englishLevels = ["Pre-k", "Beginner", "Elementary", "Pre-intermediate", "Intermediate", "Upper-intermediate", "Advanced"];
    const student = useSelector(state => state.student);
    const dispatch = useDispatch();
    const [selectedLevel, setSelectedLevel] = useState(+student.englishLevel);

    const handleLevelChange = useCallback((level) => {


        setSelectedLevel(level);
        if (typeof onChange === "function") {
            onChange(level);
        }
        console.log("level", level)
        dispatch(updateStudent({ englishLevel: level, lessonsProgram: [] }));
        // дописать, чтобы сразу показывала программу, если есть
    }, [selectedLevel]);

    useEffect(() => {
        setSelectedLevel(+student.englishLevel);
    }, [student.englishLevel]);

    return (
        <div style={{ ...styles.infoEditContainer }}>
            <OnestBoldSmall>Программа</OnestBoldSmall>
            <div style={{ ...styles.levelSelectorContainer }}>
                {englishLevelsObjectArray.map(el => (
                    <div key={el.index}>


                        <label
                            style={{
                                ...styles.levelSelectorButton,
                                ...(+selectedLevel === +el?.index && styles.levelSelectorButtonActive)
                            }}

                            onClick={() => handleLevelChange(el.index)}
                        >
                            <OnestNormalSmall>{el.name}</OnestNormalSmall>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const StudentProfileInfoEdit = ({ levels, image, addCashImage }) => {
    const student = useSelector(state => state.student);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const [name, setName] = useState(student.name || "");
    const [age, setAge] = useState(student.age || 7);
    const [additionalInfo, setAdditionalInfo] = useState(student.additionalInfo || "");
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setName(student.name || "");
        setAge(student.age || 7);
        setAdditionalInfo(student.additionalInfo || "");
        setIsSaveButtonDisabled(true);
    }, [student.name, student.age, student.additionalInfo]);


    async function studentDataUpdateSubmit(e) {
        e.preventDefault()
        setIsLoading(true)
        setIsSaveButtonDisabled(true)
        if (student.studentId === -1) {
            axios.post(config.BACKEND_ADDR + "/students/", {
                name: name,
                age: parseInt(age),
                english_skill: '' + student.englishLevel,
                comment: additionalInfo
            }).then(res => {
                if (image) {
                    axios.patch(config.BACKEND_ADDR + "/students/", {
                        student_id: res.data.id,
                        image: image
                    }).then(() => {

                        addCashImage('');
                        navigate(`/service/students/${res.data.id}`);
                        window.location.reload();
                    }).catch((err) => {
                        console.log(err);
                        alert("Ошибка обновления данных ученика");
                    })
                } else {
                    addCashImage('');
                    navigate(`/service/students/${res.data.id}`);
                    isLoading(false)
                    window.location.reload();
                }
                navigate(`/service/students/${res.data.id}`)
                window.location.reload();
            }).catch((err) => {
                console.log(err)
                alert("Ошибка обновления данных ученика")
            })
            return
        }

        const filteredLevels = levels.filter(level => +level.name === +student.englishLevel);
        const materials = filteredLevels[0].materials;

        if (materials) {

            let nextLessonProgram = [];
            for (const material of materials) {
                try {



                    const response = await axios.post(config.BACKEND_ADDR + "/program/newStep/", {
                        student_id: student.studentId,
                        level_id: student.englishLevel,
                        material_id: material.id,
                    });

                    let createdAt = new Date(response.data.created_at).getTime();
                    let updatedAt = new Date(response.data.updated_at).getTime();
                    let deletedAt = response.data.deleted_at ? new Date(response.data.deleted_at).getTime() : null;

                    const newData = {
                        ...response.data,
                        created_at: createdAt,
                        updated_at: updatedAt,
                        deleted_at: deletedAt
                    };

                    nextLessonProgram = [...nextLessonProgram, newData];


                } catch (error) {
                    console.error(error);
                }
            }


            dispatch(updateStudent({
                lessonsProgram: nextLessonProgram
            }));



        }

        axios.patch(config.BACKEND_ADDR + "/students/", {
            student_id: student.studentId,
            name: name,
            age: parseInt(age),
            english_skill: '' + student.englishLevel,
            comment: additionalInfo,
            teacher_id: user.id,
            balance: student.balance
        }).then(() => {
            dispatch(updateStudent({
                studentId: student.studentId,
                name: name,
                age: parseInt(age),
                englishLevel: student.englishLevel,
                additionalInfo: additionalInfo,
                balance: student.balance
            }))

        }).catch((err) => {
            console.log(err)
            alert("Ошибка обновления данных ученика")
        }).finally(() => {
            setIsLoading(false)
            setIsSaveButtonDisabled(true)
        })
    }

    // Обработчик события покидания страницы
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!isSaveButtonDisabled) {
                e.preventDefault();
                e.returnValue = ""; // Показывает стандартное предупреждение браузера
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isSaveButtonDisabled]);

    // Обработчик для предупреждения при навигации внутри приложения
    useEffect(() => {
        const handleRouteChange = (e) => {
            if (!isSaveButtonDisabled) {
                const confirmLeave = window.confirm("У вас есть несохраненные изменения. Хотите остаться на  странице?");
                if (!confirmLeave) {
                    e.preventDefault();
                }
            }
        };

        window.addEventListener("popstate", handleRouteChange);

        return () => {
            window.removeEventListener("popstate", handleRouteChange);
        };
    }, [isSaveButtonDisabled]);

    useBlocker(() => {
        if (!isSaveButtonDisabled) {
            const confirmLeave = window.confirm("У вас есть несохраненные изменения. Хотите остаться на странице?");
            return confirmLeave; // Возвращает true, если пользователь подтвердил переход
        }
        return false; // Разрешает переход, если нет несохраненных изменений
    });

    const handleChangeName = (e) => {
        setName(e.target.value)
        let isFormIncomplete = false;
        if (e.target.value.length === 0 || !(age > 0) ){
            isFormIncomplete = true;
        }
        console.warn('isFormIncomplete', isFormIncomplete)
        setIsSaveButtonDisabled(isFormIncomplete);
    }

    const handleChangeAge = (e) => {
        setAge(e.target.value)
        let isFormIncomplete = false;
        if (name.length === 0 || !e.target.value){
            isFormIncomplete = true;
        }
        console.warn('isFormIncomplete', isFormIncomplete)
        setIsSaveButtonDisabled(isFormIncomplete);
    }

    const handleChangeLevel = (level) => {
        let isFormIncomplete = false;
        if (name.length === 0 || !(age > 0)){
            isFormIncomplete = true;
        }
        console.warn('isFormIncomplete', isFormIncomplete)
        setIsSaveButtonDisabled(isFormIncomplete);
    }

    const handleChangeAdditionalInfo = (e) => {
        setAdditionalInfo(e.target.value)
        let isFormIncomplete = false;
        if (name.length === 0 || !(age > 0)){
            isFormIncomplete = true;
        }
        console.warn('isFormIncomplete', isFormIncomplete)
        setIsSaveButtonDisabled(isFormIncomplete);
    }

    return (

        <form
            style={{ ...styles.infoEditForm }}
            onSubmit={studentDataUpdateSubmit}
        >
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <TextInput
                    width={205}
                    height={50}
                    title={"Имя и фамилия *"}
                    value={name}
                    onChange={handleChangeName}
                    inputBackgroundColor={name.length > 0 ? color_grey_ultra_light : color_red_ultra_light}
                />
                <TextInput
                    width={205}
                    height={50}
                    title={"Возраст *"}
                    type="number"
                    value={age}
                    onChange={handleChangeAge}
                    inputBackgroundColor={age > 0 ? color_grey_ultra_light : color_red_ultra_light}
                />
            </div>
            <LevelSelectorButtons
                onChange={handleChangeLevel}
                defaultValue={student.englishLevel} />
            <TextInput
                multiline={true}
                width={420}
                height={128}
                title={"Дополнительная информация"}
                value={additionalInfo}
                onChange={handleChangeAdditionalInfo}
                inputBackgroundColor={color_grey_ultra_light}
            />
            <div
                style={{
                    marginTop: 25,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}>
                {isLoading && <OnestNormalDefault style={{marginLeft: 16, marginRight: 16}} >Сохранение...</OnestNormalDefault>}
                {isLoading && 
                    <ClipLoader 
                        color={color_red_default} 
                        size={35} 
                        loading={isLoading} 
                        />
                }
                <Button
                    width={145}
                    height={40}
                    backgroundColor={isSaveButtonDisabled ? color_grey_light : color_red_default}
                    color={color_white}
                    disabled={isSaveButtonDisabled}
                    type="submit"
                    style={{ marginLeft: "16px" }}>

                    <OnestNormalDefault>Сохранить</OnestNormalDefault>
                </Button>
            </div>
        </form>
    )
}