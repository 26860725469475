import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useBlocker } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { styles } from "./styles";
import { Button } from "../../../controls/Button/Button";
import { OnestBoldSmall, OnestNormalDefault, OnestNormalSmall, OnestNormalBig } from "../../../styled/TextComponents";
import { color_green_light, color_grey_light, color_red_default, color_white } from "../../../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../../Config";
import { updateStudent } from "../../../../store/StudentSlice";
import { addZeroToTime, weekDayToFullWeekName } from "../../../../pages/personal/JournalPage";
import { weekDayToNumber } from "../../../../constants/weekDayToNumber";
import { StudentScheduleBlock } from "../../StudentSchedule";
import { WeekdayTimeSelector } from "./WeekdayTimeSelector";
import { FormCheckBox } from "../../../forms/authorization/RegisterForm";
import RadioBoxEmpty from "../../../controls/check-boxes/RadioBoxEmpty";

const weekDayArr = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

const weekDurations = {
    1: "На неделю",
    4: "На месяц",
    13: "На квартал",
    26: "На полгода",
    52: "На год"
};


const WeekdaySelectorButtons = ({ selectedWeekdays, onToggleWeekday }) => {

    return (
        <div style={{ ...styles.infoEditContainer }}>
            <OnestBoldSmall>День недели</OnestBoldSmall>
            <div style={{ ...styles.weekdaySelectorContainer }}>
                {weekDayArr.map(el => (
                    <div key={el}>
                        <input
                            style={{ display: "none" }}
                            type="checkbox"
                            id={el}
                            checked={selectedWeekdays.includes(el)}
                            onChange={() => onToggleWeekday(el)}
                        />
                        <label
                            style={{
                                ...styles.weekDaySelectorButton,
                                ...(selectedWeekdays.includes(el) && styles.weekDaySelectorButtonActive)
                            }}
                            htmlFor={el}
                        >
                            <OnestNormalSmall>{el}</OnestNormalSmall>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};


export const StudentScheduleEdit = () => {
    const student = useSelector(state => state.student);

    const user = useSelector(state => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWeekdays, setSelectedWeekdays] = useState(student.lessonsSchedule.map(el => el.weekDayShort));
    const [startDate, setStartDate] = useState(new Date());
    const [selectedTimes, setSelectedTimes] = useState(
        student.lessonsSchedule.reduce((acc, lesson) => {
            acc[lesson.weekDayShort] = lesson.time;
            return acc;
        }, {})
    );
    const [weekDuration, setWeekDuration] = useState(student?.numberWeeks ? student?.numberWeeks : 26);

    useEffect(() => {
        console.warn('student', student);
        if (student.studentId !== -1 && student.startday && student.numberWeeks) {

            setWeekDuration(student.numberWeeks);
            setStartDate(new Date(student.startday));
        }
    }, [student])

    const handleCheckChangeDuration1 = () => {
        setIsSaveButtonDisabled(false);
        setWeekDuration(1);
    }

    const handleCheckChangeDuration4 = () => {
        setIsSaveButtonDisabled(false);
        setWeekDuration(4);
    }
    const handleCheckChangeDuration13 = () => {
        setIsSaveButtonDisabled(false);
        setWeekDuration(13);
    }

    const handleCheckChangeDuration26 = () => {
        setIsSaveButtonDisabled(false);
        setWeekDuration(26);
    }

    const handleCheckChangeDuration52 = () => {
        setIsSaveButtonDisabled(false);
        setWeekDuration(52);
    }

    const handleStartDateChange = (e) => {
        setIsSaveButtonDisabled(false);
        setStartDate(new Date(e.target.value));
    };

    const handleToggleWeekday = (weekday) => {
        setSelectedWeekdays(prev => {
            const newWeekdays = prev.includes(weekday)
                ? prev.filter(wd => wd !== weekday)
                : [...prev, weekday].sort((a, b) => weekDayArr.indexOf(a) - weekDayArr.indexOf(b));
            let isButtonDisabled = false;
            for (let weekdayItem of newWeekdays) {
                if (!selectedTimes[weekdayItem]) {
                    isButtonDisabled = true;
                }
            }
            setIsSaveButtonDisabled(isButtonDisabled);
            return newWeekdays;
        });
    };

    const handleTimeChange = (weekday, time) => {
        let isButtonDisabled = false;
        console.warn('handleTimeChange', time)
        for (let weekdayItem of selectedWeekdays) {
            if (!selectedTimes[weekdayItem]) {
                if (weekdayItem !== weekday) {
                    isButtonDisabled = true;
                }
            }
        }

        setIsSaveButtonDisabled(isButtonDisabled);
        setSelectedTimes(prev => ({
            ...prev,
            [weekday]: time
        }));
    };

    const handleSubmit = (e) => {
        setIsSaveButtonDisabled(true);
        e.preventDefault();
        if (student.studentId === -1) {
            alert("Сначала заполните профиль и сохраните его!")
            return
        }
        setIsLoading(true);
        axios.put(Config.BACKEND_ADDR + "/lessons/make_schedule", {
            student_id: student.studentId,
            schedule_items: Object.entries(selectedTimes).filter(el => selectedWeekdays.includes(el[0])).map(([key, value]) => {
                console.log("key: ", key, "value: ", value)
                const [timeFrom, timeTo, quantity] = value?.split(" - ") || ["", "", "1"];
                const parsedQuantity = parseInt(quantity.charAt(0), 10);
                return {
                    weekday: weekDayToNumber[key],
                    start_time: timeFrom + ":00",
                    end_time: timeTo + ":00",
                    quantity: parsedQuantity,
                }
            }),
            note: null,
            start_from: startDate,
            duration: weekDuration,

        }).then(() => {

            axios.patch(Config.BACKEND_ADDR + "/students/", {
                student_id: student.studentId,
                startday: startDate,
                numberweeks: weekDuration,
            }).then(() => {
                dispatch(updateStudent({
                    studentId: student.studentId,
                    startday: startDate,
                    numberWeeks: weekDuration,
                }))
            }).catch((err) => {
                console.log(err)
                alert("Ошибка обновления данных ученика")
            })


            axios.get(Config.BACKEND_ADDR + `/lessons/`).then((res) => {
                dispatch(updateStudent({
                    "lessonsSchedule": res.data.filter(el => el.student_id === student.studentId && el.not_scheduled !== true).map(el => {
                        let startTime = new Date(el.start_time)
                        let endTime = new Date(el.end_time)
                        return {
                            weekDay: weekDayToFullWeekName(el.weekday).full,
                            weekDayShort: weekDayToFullWeekName(el.weekday).short,
                            time: `${addZeroToTime(startTime.getHours())}:${addZeroToTime(startTime.getMinutes())} - ${addZeroToTime(endTime.getHours())}:${addZeroToTime(endTime.getMinutes())} - ${el.quantity} занятий`,
                            startTime: startTime,
                        }
                    }).filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.weekday === value.weekday && t.time === value.time
                        ))
                    )
                }))
            }).finally(() => {
                setIsLoading(false);
                setIsSaveButtonDisabled(false);
            })
        })


    };
    /* 
        useEffect(() => {
           let isButtonDisabled = false;
    
            for (let weekday of selectedWeekdays) {
                if (!selectedTimes[weekday]) {
                    isButtonDisabled = true;
                }
            }
            setIsSaveButtonDisabled(false);
        }, [selectedWeekdays, selectedTimes, ])
     */

    // Обработчик события покидания страницы
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!isSaveButtonDisabled) {
                e.preventDefault();
                e.returnValue = ""; // Показывает стандартное предупреждение браузера
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isSaveButtonDisabled]);

    // Обработчик для предупреждения при навигации внутри приложения

    useBlocker(() => {
        if (!isSaveButtonDisabled) {
            const confirmLeave = window.confirm("У вас есть несохраненные изменения. Хотите остаться на странице?");
            return confirmLeave; // Возвращает true, если пользователь подтвердил переход
        }
        return false; // Разрешает переход, если нет несохраненных изменений
    });

    // Обработчик для предупреждения при навигации внутри приложения
    useEffect(() => {
        let previousLocation = location.pathname;

        const handleRouteChange = () => {
            if (!isSaveButtonDisabled && location.pathname !== previousLocation) {
                const confirmLeave = window.confirm("У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?");
                if (!confirmLeave) {
                    navigate(previousLocation); // Возвращаем пользователя на предыдущую страницу
                } else {
                    previousLocation = location.pathname; // Обновляем предыдущий маршрут
                }
            }
        };

        handleRouteChange();
    }, [isSaveButtonDisabled, location, navigate]);

    return (
        <form style={{ ...styles.infoEditForm }} onSubmit={handleSubmit}>
            <WeekdaySelectorButtons
                selectedWeekdays={selectedWeekdays}
                onToggleWeekday={handleToggleWeekday}
            />
            <WeekdayTimeSelector
                selectedWeekdays={selectedWeekdays}
                selectedTimes={selectedTimes}
                onTimeChange={handleTimeChange}
            />
            <div style={{ marginTop: 25 }}>
                <OnestBoldSmall>Начало занятий</OnestBoldSmall>
                <input
                    lang="ru"
                    type="date"
                    onChange={handleStartDateChange}
                    value={startDate instanceof Date && !isNaN(startDate) ? startDate.toISOString().split('T')[0] : ""}
                    placeholder="ДД.ММ.ГГГГ"
                    pattern="\d{2}.\d{2}.\y{4}"
                    style={{
                        ...styles.timeSelect,
                        alignSelf: "center",
                        width: '100%',
                        marginTop: 5,
                    }}
                />
            </div>

            <div style={{ marginTop: 25 }}>
                <OnestBoldSmall>Повтор расписания</OnestBoldSmall>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={weekDuration === 1}
                        onChange={handleCheckChangeDuration1}
                    />
                    <OnestNormalSmall>
                        {weekDurations[1]}
                    </OnestNormalSmall>
                </label>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={weekDuration === 4}
                        onChange={handleCheckChangeDuration4}
                    />
                    <OnestNormalSmall>
                        {weekDurations[4]}
                    </OnestNormalSmall>
                </label>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={weekDuration === 13}
                        onChange={handleCheckChangeDuration13}
                    />
                    <OnestNormalSmall>
                        {weekDurations[13]}
                    </OnestNormalSmall>
                </label>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={weekDuration === 26}
                        onChange={handleCheckChangeDuration26}
                    />
                    <OnestNormalSmall>
                        {weekDurations[26]}
                    </OnestNormalSmall>
                </label>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={weekDuration === 52}
                        onChange={handleCheckChangeDuration52}
                    />
                    <OnestNormalSmall>
                        {weekDurations[52]}
                    </OnestNormalSmall>
                </label>
            </div>

            <div style={{ marginTop: 25, display: "flex", justifyContent: "flex-end" }}>

                {isLoading && <OnestNormalDefault>Сохранение...</OnestNormalDefault>}
                {isLoading && <ClipLoader color={color_red_default} size={35} loading={isLoading} />}
                <Button
                    width={145}
                    height={40}
                    backgroundColor={isSaveButtonDisabled ? color_grey_light : color_red_default}
                    disabled={isSaveButtonDisabled}
                    color={color_white}
                    type="submit">
                    <OnestNormalDefault>Сохранить</OnestNormalDefault>
                </Button>
            </div>
        </form>
    );
};
