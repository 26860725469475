import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styles from "./Styles";
import RadioBoxEmpty from "../../controls/check-boxes/RadioBoxEmpty";
import { OnestNormal, OnestNormalDefault, OnestSemiBoldSmall, OnestNormalSmall, OnestBoldBig, } from "../../styled/TextComponents";
import { Button } from "../../controls/Button/Button";
import {
    color_white,
    color_grey_light,
    color_grey_ultra_light,
    color_red_default,
    color_red_ultra_light,
    color_grey_dark,
} from "../../../constants/colors";
import {
    BsArrowLeft,
    BsArrowRight,
    BsArrowsAngleExpand,
    BsArrowsExpand,
    BsClock,
    BsDoorOpen, BsRepeat,
    BsReply, BsReplyFill
} from "react-icons/bs";
import { set } from "react-hook-form";
import minutesToStr from "../../../utilities/munutesToStr";

const ModalReschedule = ({ isOpen, onClose, onSave, lessonData }) => {
    const [isDelete, setIsDelete] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState(lessonData.timeSlot);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleRescheduleSubmit = () => {
        if (!selectedDate || !selectedTime) {
            console.error("Invalid date or time selected");
            return;
        }
        const dateTime = new Date(`${selectedDate}T${selectedTime.split(" - ")[0]}:00`);

        const timeZoneOffset = dateTime.getTimezoneOffset() * 60000; // timezone Offset in milliseconds

        const localDateTime = new Date(dateTime.getTime() - timeZoneOffset);
        console.warn("isPaid ", isPaid);
        onSave(localDateTime, isDelete, isPaid, lessonData)
        onClose();
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
        setIsDelete(false);
        setIsButtonDisabled(false);
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
        setIsDelete(false);
        setIsButtonDisabled(false);
    };

    const handleClickPayOption = () => {
        setIsPaid(true);
        setIsDelete(true);
        setIsButtonDisabled(false);
    }

    const handleClickFreeOption = () => {
        setIsPaid(false);
        setIsDelete(true);
        setIsButtonDisabled(false);
    }

    const onAfterOpen = () => {
        console.log("Modal opened for rescheduling");
    };

    useEffect(() => {
        setSelectedTime(lessonData.timeSlot);
        if (lessonData.dateDate) {
            const formattedDate = new Date(lessonData.dateDate)?.toISOString()?.split("T")[0];
            setSelectedDate(formattedDate);
        }
    }, [lessonData]);


    useEffect(() => {
        if (!isDelete && selectedDate && selectedTime) {
            setIsButtonDisabled(false);
        } else if (isDelete) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [isDelete, selectedDate]);

    useEffect(() => {
        setSelectedTime(lessonData.timeSlot);
        setSelectedDate(lessonData.dateDate);
    }, []);

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            onAfterOpen={onAfterOpen}
            style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.65)",
                    backdropFilter: "blur(8px)",
                    overflow: "scroll",
                },
                content: {
                    ...styles.modalContainer,
                },
            }}
        >
            <OnestBoldBig>{lessonData.studentName}</OnestBoldBig>
            <OnestNormalSmall
                style={{
                    color: lessonData.lessonStatusColor,
                    marginTop: 8,
                    marginBottom: 8,
                }}>
                {lessonData.paymentStatus}
            </OnestNormalSmall>
            <div
                style={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: color_white,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start"
                }}>
                <BsClock style={{ margin: 4 }} />
                <OnestNormalSmall style={{ margin: 4 }}>{selectedTime}</OnestNormalSmall>
            </div>
            <h3>Перенос урока</h3>
            <div
                style={{
                    marginBottom: 20,

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                }}>
                <input
                    lang="ru"
                    type="date"
                    onChange={handleDateChange}
                    onMouseEnter={() => setIsDelete(false)}
                    value={selectedDate}
                    placeholder="ДД.ММ.ГГГГ"
                    pattern="\d{2}.\d{2}.\y{4}"
                    style={{
                        ...styles.timeSelect,
                        alignSelf: "center",
                        //width: 230,
                        flex: 1,
                        backgroundColor: !isDelete ? color_grey_ultra_light : color_grey_ultra_light,
                        height: 26,
                        color: !isDelete ? color_grey_dark : color_grey_ultra_light,
                    }}
                />
                <select
                    value={selectedTime?.split(" - ")[0].split(":")[0] || ""}
                    onMouseEnter={() => setIsDelete(false)}
                    onChange={e => {
                        const [start, end] = selectedTime?.split(" - ") || ["", ""];
                        let startMins = start.split(":")[1] ? start.split(":")[1] : "00";
                        let endHour = +end.split(":")[0];
                        let endMinute = +end.split(":")[1];

                        if (+startMins < 30) {
                            endHour = +e.target.value;
                            endMinute = +startMins + 30;
                        } else {
                            endHour = +e.target.value + 1;
                            endMinute = +startMins - 30;
                        }
                        const newStart = `${minutesToStr(e.target.value)}:${minutesToStr(startMins)}`;
                        const newEnd = `${minutesToStr(endHour)}:${minutesToStr(endMinute)}`;
                        handleTimeChange(`${newStart} - ${newEnd}`);
                    }}
                    style={{
                        ...styles.timeSelect,
                        alignSelf: "center",
                        //width: 80,    
                        backgroundColor: !isDelete ? color_grey_ultra_light : color_grey_ultra_light,
                        color: !isDelete ? color_grey_dark : color_grey_ultra_light,
                    }}
                >
                    <option value="" disabled>часы</option>
                    {[...Array(24).keys()].map(el => (
                        <option
                            key={`hour-${el}`}
                            value={el.toString().padStart(2, "0")}
                            style={{
                                ...styles.timeSelectOption,
                            }}>
                                {el.toString().padStart(2, "0")}
                        </option>
                    ))}
                </select>
                :
                <select
                    value={selectedTime?.split(" - ")[0].split(":")[1] || ""}
                    onMouseEnter={() => setIsDelete(false)}
                    onChange={e => {
                        const [start, end] = selectedTime?.split(" - ") || ["", ""];
                        let startHour = start.split(":")[0] !== "" ? start.split(":")[0] : "00";
                        let endHour = +end.split(":")[0];
                        let endMinute = +end.split(":")[1];

                        if (+e.target.value < 30) {
                            endHour = +start.split(":")[0];
                            endMinute = +e.target.value + 30;
                        } else {
                            endHour = +start.split(":")[0] + 1;
                            endMinute = +e.target.value - 30;
                        }


                        const newStart = `${minutesToStr(startHour)}:${e.target.value}`;
                        const newEnd = `${minutesToStr(endHour)}:${minutesToStr(endMinute)}`;
                        handleTimeChange(`${newStart} - ${newEnd}`);
                    }}
                    style={{
                        ...styles.timeSelect,
                        backgroundColor: !isDelete ? color_grey_ultra_light : color_grey_ultra_light,
                        color: !isDelete ? color_grey_dark : color_grey_ultra_light,
                    }}
                >
                    <option value="" disabled>минуты</option>
                    {["00", "15", "30", "45"].map(minute => (
                        <option
                            key={minute}
                            value={minute}
                            style={{ ...styles.timeSelectOption }}>
                            {minute}
                        </option>
                    ))}
                </select>
            </div>
            <h3>Удаление урока</h3>
            <div style={{ marginBottom: 20 }}>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={isDelete && isPaid}
                        onChange={handleClickPayOption}
                    />
                    <OnestNormal>Удалить урок и списать деньги с баланса</OnestNormal>
                </label>
                <label style={{ ...styles.radioBoxRow }}>
                    <RadioBoxEmpty
                        checked={isDelete && !isPaid}
                        onChange={handleClickFreeOption}
                    />
                    <OnestNormal>Удалить урок и не списывать деньги с баланса</OnestNormal>
                </label>
            </div>
            <Button
                width={"100%"}
                height={60}
                backgroundColor={isButtonDisabled ? color_grey_light : color_red_default}
                disabled={isButtonDisabled}
                color={color_white}
                onClick={handleRescheduleSubmit}
            >
                <OnestNormalDefault>Сохранить</OnestNormalDefault>
            </Button>
        </ReactModal>
    );
};

export default ModalReschedule;