import React, { useState } from "react";
import FormDefault from "./FormDefault";
import { NewFormTextInput } from "../../inputs/FormTextInput/FormTextInput";
import { Button } from "../../controls/Button/Button";
import { Link, OnestNormalDefault, OnestNormalSmall } from "../../styled/TextComponents";
import { color_grey_dark, color_grey_light, color_red_default, color_white } from "../../../constants/colors";
import Config from "../../../Config";
import axios from "axios";

const RecoveryForm = () => {
    const [email, setEmail] = useState(""); // Состояние для email
    const [error, setError] = useState(""); // Состояние для ошибок валидации
    const [isEmailValid, setIsEmailValid] = useState(false); // Состояние для валидности email
    const [recoverySended, setRecoverySended] = useState(false); // Состояние для статуса отправки

    // Обработчик изменения email
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(e.target.value);
        setIsEmailValid(isValid);
        setError(""); // Сбрасываем ошибку при изменении значения
    };


    // Обработчик отправки формы
    const handleSubmit = (e) => {
        e.preventDefault(); // Предотвращаем перезагрузку страницы

        if (!isEmailValid) {
            return; // Если email невалиден, не отправляем запрос
        }

        axios
            .post(Config.BACKEND_ADDR + "/auth/recovery", {
                email: email,
                base_recovery_url: window.location.origin + window.location.pathname,
            })
            .then((response) => {
                if (response.status !== 200) {
                    alert("Непредвиденная ошибка при попытке восстановления пароля");
                } else {
                    setRecoverySended(true); // Устанавливаем статус отправки
                }
            })
            .catch(() => {
                alert("Ошибка запроса на сервер приложения");
            });
    };

    return (
        <FormDefault
            onSubmit={handleSubmit}
            title={recoverySended ? "Данные отправлены!" : "Забыли пароль?"}
            formWidth={410}
        >
            {recoverySended ? (
                <OnestNormalDefault style={{ color: color_grey_dark }}>
                    Если письмо не пришло, пожалуйста, проверьте папку “Спам” или напишите в{" "}
                    <Link href="mailto:speakid.help@mail.ru">поддержку</Link>.
                </OnestNormalDefault>
            ) : (
                <>
                    <NewFormTextInput
                        placeholder={"example@mail.ru"}
                        type={"email"}
                        title={"Email"}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    {error && (
                        <OnestNormalSmall style={{ color: color_red_default }}>
                            {error}
                        </OnestNormalSmall>
                    )}
                    <Button
                        backgroundColor={isEmailValid ? color_red_default : color_grey_light}
                        color={color_white}
                        outline={false}
                        width={350}
                        height={63}
                        active={true}
                        type={"submit"}
                        disabled={!isEmailValid}
                    >
                        Восстановить пароль
                    </Button>
                </>
            )}
        </FormDefault>
    );
};

export default RecoveryForm;