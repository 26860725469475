import React, { useState } from "react";
import { ModalDefault } from "../containers/ModalDefault";
import { ButtonDefault } from "../controls/Button/ButtonDefault";
import { BsX } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../Config";
import { updateStudent } from "../../store/StudentSlice";
import {
    color_grey_dark,
    color_grey_light,
    color_grey_ultra_light,
    color_red_default
} from "../../constants/colors";
import { OnestNormalDefault, OnestNormalSmall } from "../styled/TextComponents";

const ModalPrompt = ({
    modalOpen,
    setModalOpen,
    changeData,
    setChangeData,
    student,
    user, 
    onSave,
}) => {
    const dispatch = useDispatch();

    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    const handleSave = () => { 
        onSave(); 
        setModalOpen(false); 
       setIsSaveButtonDisabled(true);
    };

    return (
        <ModalDefault
            onRequestClose={() => setModalOpen(false)}
            isOpen={modalOpen}
        >
            <div
                style={{
                    width: 265,
                    height: 245,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                    borderRadius: 20,
                    padding: 20,
                    justifyContent: "space-evenly"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        cursor: "pointer",
                        width: 265
                    }}
                    onClick={() => setModalOpen(false)}
                >
                    <BsX
                        style={{
                            fontSize: 30,
                            color: color_grey_dark
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10
                    }}
                >
                    <div
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: 10,
                            backgroundColor: changeData.changeIconColor,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <img src={changeData.changeIcon} alt="icon" />
                    </div>
                    <OnestNormalDefault>{changeData.changeTitle ? changeData.changeTitle : ""}</OnestNormalDefault>
                </div>
                <div>
                    <div
                        style={{
                            margin: 2,
                            paddingBottom: 5,
                            width: 305
                        }}
                    >
                        <OnestNormalSmall>{changeData.changeText ? changeData.changeText : ""}</OnestNormalSmall>
                    </div>
                    <input
                        style={{
                            width: 265 - 15,
                            height: 50,
                            borderRadius: 10,
                            paddingLeft: 15,
                            color: color_grey_dark,
                            backgroundColor: color_grey_ultra_light,
                            border: "0px solid grey"
                        }}
                        type="number"
                        min={0}
                        step={5}
                        value={changeData.changeValue || 0}
                        onChange={(e) => {
                            setIsSaveButtonDisabled(false);
                            setChangeData({
                                ...changeData,
                                changeValue: e.target.value === "" ? 0 : e.target.value
                            });
                        }}
                    />
                </div>
                <div onClick={handleSave}>
                    <ButtonDefault
                        width={265}
                        height={40}
                        backgroundColor={isSaveButtonDisabled ? color_grey_light : color_red_default}
                        disabled={isSaveButtonDisabled}
                    >
                        Сохранить
                    </ButtonDefault>
                </div>
            </div>
        </ModalDefault>
    );
};

export default ModalPrompt;
